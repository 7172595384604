html {
  font-size: 16px;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

ds-message {
  word-break: break-word;
}

// the configuration modal gets a margin from somewhere, which leads to an additional scrollbar
// keep until all integrated apps are on Density 9.1+
ds-box#configuration-dialog, ds-box#case-selection-dialog {
  margin: 0 !important;
}

// TODO remove after update to Density 9.1
// fix a Density defect which causes the case selection overlay to not be scrollable
.ds-box__content-wrapper {
  overflow-y: auto !important;
}

// filter slows down the UI, overwrite Density styles
.shadow-modal,
.overlay.shadow-modal,
ds-box.ds-box--shadow-modal,
ds-toast,
.ds-box--shadow-component,
.ds-box--shadow-base {
  filter: none !important;
}

// use box-shadow instead where required
ds-toast, .ds-box--shadow-component, .ds-box--shadow-base {
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px;
}

ds-tooltip {
  color: white;
}
